import "../stylesheets/portal.css"

function importAll(r) {
  r.keys().forEach(r)
}

importAll(require.context("../../components", true, /_component.scss$/))

function loadScript(name, src) {
  window[name] ||= import(`../javascript/src/portal/${name}`);
}

function loadChunks() {
  if (document.querySelectorAll(".experiences.show, .experiences.reviews, .bookings.success").length) {
    loadScript("experience")
  }

  if(document.querySelector(".explore.index")) {
    loadScript("explore")
  }

  if(document.querySelector(".bookings")) {
    loadScript("form")
  }
}

import("../javascript/src/portal/critical").then(() => {
  loadChunks()
});

document.addEventListener('turbo:load', () => {
  loadChunks()
})
